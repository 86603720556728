<template>
  <div class="resume-online-detail-box">
    <van-nav-bar title="在线简历预览" left-text="返回" left-arrow @click-left="onClickLeft" v-if="!$root.isInMini"/>
    <div class="detail-list" v-if="this.query.deliveryStateCode">
      <div class="filed-box">
        <van-field
          v-model="query.stateDesc"
          name="当前简历状态"
          label="当前简历状态"
          required
          readonly
          placeholder
          :rules="[{ required: true }]"
          @click="resumeShow"
        >
          <template #button>
            <van-icon v-if="resumeStatusPicker" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </template>
        </van-field>
        <van-popup v-model="resumeStatusPicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="deliveryStateList"
            @cancel="resumeStatusPicker = false"
            @confirm="resumeStatusConfirm"
          >
            <template #option="item">
              <p style="text-align: center">{{ item.name }}</p>
            </template>
          </van-picker>
        </van-popup>
      </div>
      <div class="base-con" v-if="baseInfo">
        <div class="base-box">
          <div>
            <span>{{ baseInfo.name }}</span>
            <img
              v-if="baseInfo.gender"
              :src="
                require(`../../assets/deliveryRecord/${baseInfo.gender}.png`)
              "
              alt
            />
          </div>
          <p>
            {{ baseInfo.jobStartDate | yearFilter }}年工作经验
            <span
              v-if="baseInfo.currentDegreeName"
            >·{{ baseInfo.currentDegreeName }}</span>
            <span v-if="baseInfo.age">·{{ baseInfo.age }}</span>
          </p>
        </div>
        <div class="base-img">
          <img src alt />
        </div>
      </div>
      <div class="list-box" v-if="baseInfo">
        <div class="title">求职意向</div>
        <div class="list-tag">
          <span>{{ baseInfo.expectedPositionNames || '-' }}</span>
          <em></em>
          <span>{{ baseInfo.expectSalary || '-' }}</span>
        </div>
        <div class="base-tag">
          全职
          <span v-if="baseInfo.expectedCity">·{{ baseInfo.expectedCity }}</span>
          <span v-if="baseInfo.arriveDate">· 到岗时间{{ baseInfo.arriveDate }}</span>
        </div>
        <div class="state">目前状态：{{ baseInfo.currentStatus }}</div>
      </div>
      <div class="list-box" v-if="baseInfo">
        <div class="title">自我评价</div>
        <div class="intr">{{ baseInfo.personalAdvantage }}</div>
      </div>
      <div class="list-box" v-if="works">
        <div class="title">工作经历</div>
        <div class="work-exp" v-for="(work, i) in works" :key="i">
          <div class="company">
            <span>{{ work.companyName }}</span>
            <i>
              {{ work.startDate }}-{{
              work.endDate == '1970-01' ? '至今' : work.endDate
              }}
            </i>
          </div>
          <p>{{ work.jobTitle }}</p>
          <div class="content" v-html="work.jobDesc"></div>
        </div>
      </div>
      <div class="list-box" v-if="education">
        <div class="title">教育经历</div>
        <div class="edu-exp" v-for="(edu, i) in education" :key="i">
          <div class="school">
            <span>{{ edu.schoolName }}</span>
            <i>
              {{ edu.startDate }}-{{
              edu.endDate == '1970-01' ? '至今' : edu.endDate
              }}
            </i>
          </div>
          <p>
            {{ edu.majorSubjects }}·{{ edu.recordOfFormalSchoolingValue }}·{{
            edu.schoolTypeValue
            }}
          </p>
          <!-- <div class="content">1.工作内容123工作内容123工作内容</div> -->
        </div>
      </div>
      <div class="list-box" v-if="projects">
        <div class="title">项目经验</div>
        <div class="work-exp" v-for="(project, i) in projects" :key="i">
          <div class="company">
            <span>{{ project.projectName }}</span>
            <i>
              {{ project.startDate }}-{{
              project.endDate == '1970-01' ? '至今' : project.endDate
              }}
            </i>
          </div>
          <!-- <p>{{ project.jobTitle }}</p> -->
          <div class="content" v-html="project.proDesc"></div>
        </div>
      </div>
    </div>
    <div class="btn-box" v-if="!$root.isInMini">
      <van-button round block type="info" :loading="loadingBtnPdf" @click="downLoad('pdf')">下载 Pdf</van-button>
      <van-button
        round
        block
        type="info"
        :loading="loadingBtnWord"
        @click="downLoad('docx')"
      >下载 Word</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'onlineDetail',
  data() {
    return {
      resumeStatus: '',
      resumeStatusPicker: false,
      deliveryStateList: [],
      baseInfo: null, //基本信息
      projects: null, //项目经验
      works: null, //工作经验
      education: null, //教育经历
      query: {},
      downLoadForm: {
        language: 1, //语言,(写死1)
        flag: false, //是否屏蔽联系方式(这里写死不屏蔽)
        format: null, //类型,
        userId: null, //用户ID
      },
      loadingBtnPdf: false,
      loadingBtnWord: false
    };
  },
  async mounted() {
    let { query } = this.$route;
    if (query.resumeUserId && query.deliveryId) {
      await this.init();
    }
    this.query = query;
  },
  filters: {
    yearFilter(data) {
      return (
        new Date().getFullYear() -
        (new Date(data).getFullYear() || new Date().getFullYear())
      );
    },
    statusFilter(data) {
      if (data == 'PLATFORMFILTERPASS') {
        return '企业评估中';
      } else if (data == 'COMPANYFILTERFPASS') {
        return '面试评估';
      } else if (data == 'INTERVIEWPASS') {
        return '入职结果';
      } else {
        return this.query.stateDesc || '-';
      }
    },
  },
  computed: {
    fileName() {
      if (!this.baseInfo) return '';
      const jobSeekerName = this.$route.query.userName;
      const mobile = this.$route.query.mobile;
      const format = this.downLoadForm.format;
      const companyName = this.$route.query.companyName;
      const positionName = this.$route.query.positionName;
      return `成都IT内推圈-${companyName}-${positionName}-${jobSeekerName}-${mobile}.${format}`;
    },
  },
  methods: {
    resumeShow() {
      if (this.query.deliveryStateCode) {
        this.deliveryStateList = this.options(this.query.deliveryStateCode);
        if (this.deliveryStateList.length === 0) {
          this.$toast('当前状态不能处理');
          return;
        }
        this.resumeStatusPicker = true;
      }
    },
    options(deliveryStateCode) {
      var arr = [];
      if (deliveryStateCode == 'WAITING') {
        arr = [
          { code: 'PLATFORMFILTERPASS', name: '企业评估中' },
          { code: 'COMPANYFILTERFPASS', name: '企业筛选通过·可约面试' },
          { code: 'COMPANYFILTERFAIL', name: '企业筛选未通过' },
        ];
      }
      if (deliveryStateCode == 'PLATFORMFILTERPASS') {
        arr = [
          { code: 'COMPANYFILTERFPASS', name: '企业筛选通过.可约面试' },
          { code: 'COMPANYFILTERFAIL', name: '企业筛选未通过' },
        ];
      }
      if (deliveryStateCode == 'COMPANYFILTERFPASS') {
        arr = [
          { code: 'INTERVIEWPASS', name: '面试通过' },
          { code: 'INTERVIEWFAIL', name: '面试未通过' },
        ];
      }
      if (deliveryStateCode == 'INTERVIEWPASS') {
        arr = [
          { code: 'EMPLOYED', name: '已入职' },
          { code: 'GIVE_UP_ENTRY', name: '放弃入职' },
        ];
      }
      return arr;
    },
    async init() {
      // 新跳转到这个页面，从$route.query中取到id
      const id = this.$route.query.resumeUserId;
      const deliveryId = this.$route.query.deliveryId;
      let { resumeUserId, companyName, positionName, language } = {
        resumeUserId: id,
        language: 1,
      };
      // 通过id取得简历数据,并存入本页vue
      let jobSeekerForm = await this.$http.get(
        `/selfhelpOfficialAccounts/job-seekers/preview?resumeUserId=${resumeUserId}&companyName=${companyName}&positionName=${positionName}&language=${language}&resumeLibraryType=1&deliveryId=${deliveryId}`
      );
      let { projectList, eduList, workList } = jobSeekerForm.data;
      this.works = workList;
      this.baseInfo = jobSeekerForm.data;
      this.projects = projectList;
      this.education = eduList;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    // 状态字典
    getDeliveryStateList() {
      this.$http
        .get('/selfhelpOfficialAccounts/deliveryRecord/state/search')
        .then((res) => {
          if (res.code === 200) {
            this.deliveryStateList = res.data.map((el) => {
              el.text = el.name;
              el.value = el.code;
              return el;
            });
          }
        });
    },
    resumeStatusConfirm(data) {
      if (!this.query.id) return;
      this.resumeStatusPicker = false;
      this.$dialog
        .confirm({
          message: `确认要${data.name}么?`,
        })
        .then(() => {
          this.$http
            .put(
              `/selfhelpOfficialAccounts/deliveryRecord/${this.query.id}/${data.code}`
            )
            .then(async (res) => {
              if (res.code === 200) {
                this.resumeStatus = data.code;
                this.$toast('处理成功，正在刷新数据');
                this.$forceUpdate();
              } else {
                this.$toast(res.message || '处理失败');
              }
            });
        });
    },
    // 点击下载按钮
    async downLoad(type) {
      if (type == 'docx') this.loadingBtnWord = true;
      if (type == 'pdf') this.loadingBtnPdf = true;
      this.downLoadForm.format = type;
      this.downLoadForm.userId = this.$route.query.resumeUserId;
      let blob = await this.deliveryDownLoadJobSeeker(this.downLoadForm);
      this.loadingBtnWord = false;
      this.loadingBtnPdf = false;
      this.createALink(blob, this.fileName);
    },
    // 模拟创建a标签>点击>完成下载
    async createALink(blob, fileName) {
      let aLink = document.createElement('a'); //创建a标签
      let href = window.URL.createObjectURL(blob); //创建下载的链接
      aLink.href = href; //绑定下载链接
      aLink.download = fileName || '默认名称'; //文件名+时间
      document.body.appendChild(aLink); //将a插入body
      aLink.click(); //点击下载
      document.body.removeChild(aLink); //下载完成移除元素
      window.URL.revokeObjectURL(blob); //释放掉blob对象
    },
    async deliveryDownLoadJobSeeker(form) {
      let { language, format, userId, flag } = form;
      let response = await this.$http.download(`/selfhelpOfficialAccounts/job-seekers/download?language=${language}&format=${format}&resumeUserId=${userId}&flag=${flag}`)
      if (!response) return false;
      return response;
    },
  },
};
</script>

<style lang="scss" scoped>
.resume-online-detail-box {
  height: 100%;
  width: 100%;
  background: #f7f8fa;
  display: flex;
  flex-direction: column;
  .detail-list {
    flex: 1;
    overflow-y: auto;
    .filed-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ebedf0;
      > .van-cell {
        padding-right: 16px;
        padding-left: 16px;
        flex: 1;
      }
      > .van-cell::after {
        border-bottom: none;
      }
      ::v-deep .van-field__label {
        width: auto;
      }
      ::v-deep .van-field__label span {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      ::v-deep .van-field--disabled .van-field__label {
        color: #646566;
      }
      ::v-deep .van-field__value input {
        text-align: right;
      }
      ::v-deep .van-field__control--custom {
        justify-content: right;
      }
      ::v-deep .van-field__control {
        text-align: right;
      }
    }
    .list-box {
      padding: 20px 15px;
      border-bottom: 1px solid #ebedf0;
      background: #ffffff;
      .title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        span {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }
      .list-tag {
        margin: 10px 0 15px 0;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        padding-bottom: 15px;
        span {
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        em {
          margin: 0 10px;
          height: 12px;
          width: 1px;
          display: inline-block;
          background: #cccccc;
        }
      }
      .base-tag {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
      .state {
        margin-top: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
      .intr {
        margin-top: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
      .work-exp,
      .edu-exp {
        margin: 20px 0 10px 0;
        .company,
        .school {
          display: flex;
          justify-content: space-between;
          span {
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
          i {
            font-style: normal;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .content {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-top: 10px;
        }
      }
    }
    .base-con {
      padding: 20px 15px;
      border-bottom: 1px solid #ebedf0;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .base-box {
        div {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          display: flex;
          align-items: center;
          span {
            margin-right: 3px;
          }
          img {
            width: 14px;
            height: 16px;
          }
        }
        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-top: 10px;
          font-style: normal;
        }
      }
      .base-img {
        height: 60px;
        width: 60px;
      }
    }
  }
  .btn-box {
    margin: 5px 0;
    padding: 0 46px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 10%;
    left: 0;
    right: 0;
  }
}
</style>
